export var ProjectAddress = function () {
    /*global google*/
    /*eslint no-undef: "error"*/
    function initMap() {
        var address_div = document.getElementById("address");
        var mapDiv = document.getElementById("project_address");
        if (mapDiv && (address_div === null || address_div === void 0 ? void 0 : address_div.getAttribute("data-addrlat"))) {
            var map = new google.maps.Map(mapDiv, {
                center: { lat: 37.7749, lng: -122.4194 },
                zoom: 14,
            });
            var address = address_div.getAttribute("data-address");
            var addr_lat = parseFloat(address_div.getAttribute("data-addrlat").replace(",", "."));
            var addr_lon = parseFloat(address_div.getAttribute("data-addrlon").replace(",", "."));
            var location_1 = {
                lat: addr_lat,
                lng: addr_lon
            };
            if (addr_lat && addr_lon) {
                new google.maps.Marker({
                    position: location_1,
                    map: map,
                    title: address,
                    icon: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/map-pin-square.jpg"),
                });
                map.setCenter(location_1);
            }
        }
    }
    setTimeout(initMap, 2000);
};
