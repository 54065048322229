var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useTranslation } from "react-i18next";
import { SearchBarContainer } from "../shared-components/searchInput/search-input-styles";
import { SearchInput } from "../shared-components/searchInput/SearchInput";
import BonsCoupsContext from "../../../application/Context/BonsCoupsContext";
export var SearchBar = function () {
    var _a = React.useContext(BonsCoupsContext), searchInput = _a.searchInput, handleSearchInput = _a.handleSearchInput, searchBonsCoups = _a.searchBonsCoups, handleKeyDown = _a.handleKeyDown;
    var t = useTranslation(["common"]).t;
    return (_jsxs(SearchBarContainer, { children: [_jsx("label", __assign({ htmlFor: "searchinput", style: { display: "none" } }, { children: t("Search") })), _jsx(SearchInput, { placeholder: t("Search"), id: "searchinput", type: "text", value: searchInput, onChange: handleSearchInput, handleSearch: searchBonsCoups, onKeyDown: handleKeyDown })] }));
};
