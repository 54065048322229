var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "../../shared-components/Ui/Container";
import { Row } from "../../shared-components/Ui/Row";
import { ColBootstrap } from "../../shared-components/Ui/ColBootstrap";
import { CleanFilters } from "../CleanFilters";
import { FilterCard } from "../../shared-components/SideFilter/FilterCard";
import { Filters } from "../Filters";
import { InfoBar } from "../InfoBar";
import { Pagination } from "../Pagination";
import { ViewToggler } from "./ViewToggler";
import { motion } from "framer-motion";
import { Tag, TagContainer, CompanyDetails, ImageContainer, IconContainerBig, Text, Title, Category } from "../../Styles/SharedStyledComponents";
import MapContext from "../../../../application/Context/MapContext";
export var ViewList = function () {
    var _a, _b;
    var currentAuthStatus = ((_a = document.getElementById("auth-user")) === null || _a === void 0 ? void 0 : _a.value) || false;
    var isAuthenticated = currentAuthStatus == "True" ? true : false;
    var langCode = ((_b = document.getElementById("language-code")) === null || _b === void 0 ? void 0 : _b.value) || "en";
    var t = useTranslation(["common"]).t;
    var _c = React.useContext(MapContext), view = _c.view, companiesList = _c.companiesList, projectsList = _c.projectsList, datasListIsLoading = _c.datasListIsLoading;
    var projectTypesTrans = {
        // "minier": t("Minier"),
        "public": t("Public"),
        "jesuis_jecherche": t("Je cherche/J'offre"),
        "autres": t("Autres")
    };
    return (_jsx(React.Fragment, { children: view === "list" && (_jsx(Container, { children: _jsxs(Row, { children: [_jsx(ColBootstrap, __assign({ breakPoint: "md", col: "4" }, { children: isAuthenticated && (_jsxs(FilterCard, { children: [_jsx(CleanFilters, {}), _jsx(Filters, {})] })) })), _jsxs(ColBootstrap, __assign({ breakPoint: "md", col: "8" }, { children: [_jsx(ViewToggler, {}), _jsx(InfoBar, {}), datasListIsLoading && (_jsx("div", __assign({ className: "text-center" }, { children: _jsx("div", { className: "spinner-border", role: "status" }) }))), !datasListIsLoading && (_jsxs(motion.div, __assign({ initial: { opacity: 0 }, animate: { opacity: 1 }, transition: { duration: 0.3 }, exit: { opacity: 0 } }, { children: [companiesList.length > 0 &&
                                        companiesList.map(function (c, i) {
                                            var _a, _b, _c;
                                            var title = isAuthenticated ? (_jsx("a", __assign({ href: langCode == "fr" ? "/fr".concat(c.absolute_url_fr) : "/en".concat(c.absolute_url_en) }, { children: _jsx("span", { children: c.nom_legal }) }))) : (_jsx("span", { children: c.nom_legal }));
                                            return (_jsx(CompanyDetails, { children: _jsxs(Row, { children: [_jsx(ColBootstrap, __assign({ breakPoint: "md", col: 5 }, { children: _jsx("a", __assign({ href: langCode == "fr" ? "/fr".concat(c.absolute_url_fr) : "/en".concat(c.absolute_url_en) }, { children: _jsxs(ImageContainer, __assign({ width: 20, height: 13.375, marginBottom: 15 }, { children: [c.logo_url && _jsx("img", { src: c.logo_url, alt: "" }), !c.logo_url && (_jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/default-Image-company.png"), alt: "banner" })), _jsx(IconContainerBig, { children: _jsx("img", { src: c.badge_url, alt: c.type_company ? c.type_company : "company badge" }) })] })) })) })), _jsx(ColBootstrap, __assign({ breakPoint: "md", col: 7 }, { children: _jsxs(Text, { children: [_jsx(Title, { children: title }), _jsxs(Category, { children: [c.categorie_entreprise && c.categorie_entreprise.nom && (_jsx("li", { children: c.categorie_entreprise.nom })), c.categorie_donneur_dordre && c.categorie_donneur_dordre.nom && (_jsx("li", { children: c.categorie_donneur_dordre.nom }))] }), ((_b = (_a = c.adresses) === null || _a === void 0 ? void 0 : _a.mrc) === null || _b === void 0 ? void 0 : _b.nom) && (_jsxs("div", { children: [_jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/apartment-blue-fonce.svg"), alt: "apartment" }), _jsxs("span", { children: [t("MRC"), " ", c.adresses.mrc.nom] })] })), c.description_fr && _jsx("p", { children: langCode == "fr" ? c.description_fr : c.description_en }), _jsx(TagContainer, { children: ((_c = c.code_scian_primaire) === null || _c === void 0 ? void 0 : _c.length) > 0 &&
                                                                            c.code_scian_primaire.map(function (scian) {
                                                                                return _jsx(Tag, { children: scian.code }, scian.id);
                                                                            }) })] }) }))] }) }, i));
                                        }), projectsList.length > 0 &&
                                        projectsList.map(function (c, i) {
                                            var _a, _b, _c, _d;
                                            var title = isAuthenticated ? (_jsx("a", __assign({ href: langCode == "fr" ? "/fr".concat(c.absolute_url_fr) : "/en".concat(c.absolute_url_en) }, { children: _jsx("span", { children: langCode == "fr" ? c.nom_fr : c.nom_en ? c.nom_en : c.nom_fr }) }))) : (_jsx("span", { children: langCode == "fr" ? c.nom_fr : c.nom_en ? c.nom_en : c.nom_fr }));
                                            return (_jsx(CompanyDetails, { children: _jsxs(Row, { children: [_jsx(ColBootstrap, __assign({ breakPoint: "md", col: 5 }, { children: _jsx("a", __assign({ href: langCode == "fr" ? "/fr".concat(c.absolute_url_fr) : "/en".concat(c.absolute_url_en) }, { children: _jsxs(ImageContainer, __assign({ width: 20, height: 13.375, marginBottom: 15 }, { children: [((_a = c.entreprise) === null || _a === void 0 ? void 0 : _a.logo_url) ? (_jsx("img", { src: c.entreprise.logo_url, alt: "" })) : (_jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/default-Image-company.png"), alt: "company logo" })), _jsx(IconContainerBig, { children: ((_b = c.entreprise) === null || _b === void 0 ? void 0 : _b.badge) && (_jsx("img", { src: c.entreprise.badge, alt: c.entreprise.type_company ? c.entreprise.type_company : "company badge" })) })] })) })) })), _jsx(ColBootstrap, __assign({ breakPoint: "md", col: 7 }, { children: _jsxs(Text, { children: [_jsx(Title, { children: title }), _jsxs(Category, { children: [c.categorie_projet && c.categorie_projet.nom_fr && (_jsx("li", { children: langCode == "fr"
                                                                                    ? c.categorie_projet.nom_fr
                                                                                    : c.categorie_projet.nom_en
                                                                                        ? c.categorie_projet.nom_en
                                                                                        : c.categorie_projet.nom_fr })), c.categorie_contrat && c.categorie_contrat.nom_fr && (_jsx("li", { children: langCode == "fr" ? c.categorie_contrat.nom_fr : c.categorie_contrat.nom_en }))] }), ((_d = (_c = c.adresse) === null || _c === void 0 ? void 0 : _c.mrc) === null || _d === void 0 ? void 0 : _d.nom) && (_jsxs("div", { children: [_jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/apartment-blue-fonce.svg"), alt: "apartment" }), _jsxs("span", { children: [t("MRC"), " ", c.adresse.mrc.nom] })] })), _jsx("p", { children: langCode == "fr" ? c.description_fr : c.description_en }), c.project_type && (_jsx("div", { children: _jsx(Tag, { children: projectTypesTrans[c.project_type] }) }))] }) }))] }) }, i));
                                        }), (companiesList.length > 0 || projectsList.length > 0) && _jsx(Pagination, {}), companiesList.length == 0 && projectsList.length == 0 && _jsx("div", { children: t("Aucun résultat") })] })))] }))] }) })) }));
};
