var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext } from "react";
import ProjectsContext from "../../../../application/Context/ProjectsContext";
import MarketsContext from "../../../../application/Context/MarketsContext";
import InfiniteScroll from "react-infinite-scroll-component";
import { moneyFormat } from "../../../../utils/Utils";
import { LoadingThread } from "../../shared-components/Loading/LoadingThread";
import { ProjectCart, ProjectCartBody, ProjectCartHeader, } from "./TimeLine-styles";
import { Mrc, CompanyTitle, Category, Text, Tag, Title, DateContainer, } from "../../Styles/SharedStyledComponents";
export var RenderProjects = function (_a) {
    var _b;
    var application = _a.application, rangeValue = _a.rangeValue;
    var context = application == "markets"
        ? useContext(MarketsContext)
        : useContext(ProjectsContext);
    var projects = context.projects, loadNewProjects = context.loadNewProjects, hasMoreData = context.hasMoreData;
    var _c = __read(React.useState(""), 2), projectToOpen = _c[0], setProjectToOpen = _c[1];
    var langCode = ((_b = document.getElementById("language-code")) === null || _b === void 0 ? void 0 : _b.value) || "en";
    var urlPrefix = application == "markets"
        ? langCode == "fr"
            ? "/fr/marches"
            : "/en/markets"
        : langCode == "fr"
            ? "/fr/avis"
            : "/en/notices";
    var toggleProject = function (e) {
        var eleId = e.target.getAttribute("data-id");
        if (projectToOpen === eleId) {
            setProjectToOpen("");
        }
        else {
            setProjectToOpen(eleId);
        }
    };
    var calculatPositionOfProject = function (monthStartIndex, monthEndIndex, dayStart, dayEnd) {
        var positionStart = monthStartIndex * 60 + dayStart * 2;
        var positionEnd = monthEndIndex * 60 + dayEnd * 2;
        return [positionStart, positionEnd];
    };
    return (_jsx(InfiniteScroll, __assign({ dataLength: projects.length, next: loadNewProjects, hasMore: hasMoreData, loader: _jsx(LoadingThread, {}), height: 500 }, { children: projects.map(function (item, i) {
            var _a, _b, _c, _d;
            var startDate;
            application == "markets"
                ? (startDate = item.date_publication_avis
                    ? new Date("".concat(item.date_publication_avis))
                    : new Date(item.date_creation))
                : (startDate = item.date_publication_avis
                    ? new Date("".concat(item.date_publication_avis))
                    : new Date(item.date_creation));
            var endDate = item.date_fermeture_avis
                ? new Date("".concat(item.date_fermeture_avis))
                : startDate;
            var monthStartIndex = startDate.getMonth();
            var monthEndIndex = endDate.getMonth();
            var dayStart = startDate.getDate();
            var yearStart = startDate.getFullYear();
            var yearEnd = endDate.getFullYear();
            var dayEnd = endDate.getDate();
            var _e = __read(calculatPositionOfProject(monthStartIndex, monthEndIndex, dayStart, dayEnd), 2), posS = _e[0], posE = _e[1];
            var leftPosion = posS + "px";
            var width = posE - posS;
            var display = "block";
            if (yearStart < yearEnd && rangeValue[0] == yearStart) {
                width = 720 - posS;
            }
            else if (rangeValue[0] < yearEnd) {
                width = 720;
                leftPosion = 0;
            }
            else if (rangeValue[0] == yearEnd && yearStart < yearEnd) {
                var remider = 720 - posE;
                width = 720 - remider;
                leftPosion = 0;
            }
            if (yearStart == yearEnd &&
                monthStartIndex == monthEndIndex &&
                dayStart > dayEnd) {
                width = 200;
            }
            else if (yearStart == yearEnd && monthStartIndex > monthEndIndex) {
                width = 200;
            }
            var f = Intl.DateTimeFormat(langCode, {
                day: "numeric",
                month: "long",
                year: "numeric",
            });
            var _f = item.is_avis ? React.useContext(ProjectsContext) : React.useContext(MarketsContext), projectsTypes = _f.projectsTypes, projectsJesuisJechercheTypes = _f.projectsJesuisJechercheTypes;
            var projectTypeJSJC = null;
            var projectTypeName = null;
            var projectTypeFind = projectsTypes.find(function (e) { return e.id === item.project_type; });
            projectTypeName = projectTypeFind ? projectTypeFind.nom : item.project_type;
            if (item.project_type === "jesuis_jecherche") {
                var projectJSJCfind = projectsJesuisJechercheTypes.find(function (e) { return e.id === item.type_de_projet_je_suis_je_cherche; });
                projectTypeJSJC = projectJSJCfind ? projectJSJCfind.nom : item.type_de_projet_je_suis_je_cherche;
            }
            return (_jsxs(ProjectCart, __assign({ style: {
                    position: "relative",
                    left: leftPosion,
                    width: projectToOpen === "project-".concat(i) ? "fit-content" : width,
                    maxWidth: projectToOpen === "project-".concat(i)
                        ? width < 400 || width > 800
                            ? "400px"
                            : width
                        : "none",
                    display: display,
                } }, { children: [_jsxs(ProjectCartHeader, __assign({ "data-id": "project-".concat(i), onClick: function (e) { return toggleProject(e); } }, { children: [projectToOpen === "project-".concat(i) && (_jsx(Title, { children: _jsx("a", __assign({ href: langCode == "fr"
                                        ? "".concat(urlPrefix, "/").concat(item.slug_fr, "/")
                                        : "".concat(urlPrefix, "/").concat(item.slug_en, "/") }, { children: langCode == "fr" && item.nom_fr
                                        ? item.nom_fr
                                        : langCode == "en" && item.nom_en
                                            ? item.nom_en
                                            : item.nom_fr })) })), projectToOpen !== "project-".concat(i) && (_jsx("div", __assign({ className: "card-title", "data-id": "project-".concat(i) }, { children: _jsx("a", __assign({ href: langCode == "fr"
                                        ? "".concat(urlPrefix, "/").concat(item.slug_fr, "/")
                                        : "".concat(urlPrefix, "/").concat(item.slug_en, "/"), style: { color: "#223654" } }, { children: langCode == "fr" && item.nom_fr
                                        ? item.nom_fr
                                        : langCode == "en" && item.nom_en
                                            ? item.nom_en
                                            : item.nom_fr })) }))), _jsx("img", { src: projectToOpen !== "project-".concat(i)
                                    ? "".concat(process.env.IMG_URL, "/static/vendors/images/icons/chevron-down.svg")
                                    : "".concat(process.env.IMG_URL, "/static/vendors/images/icons/chevron-up.svg"), alt: "arrow", "data-id": "project-".concat(i), style: projectToOpen !== "project-".concat(i)
                                    ? { cursor: "pointer", margin: "0 0 0 10px" }
                                    : {
                                        position: "relative",
                                        top: "-4px",
                                        cursor: "pointer",
                                        margin: "0 0 0 10px",
                                    } })] })), projectToOpen === "project-".concat(i) && (_jsx("a", __assign({ href: langCode == "fr"
                            ? "".concat(urlPrefix, "/").concat(item.slug_fr, "/")
                            : "".concat(urlPrefix, "/").concat(item.slug_en, "/") }, { children: _jsxs(ProjectCartBody, { children: [item.adresse && item.adresse.mrc && (_jsxs(Mrc, { children: [_jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/building.svg"), alt: "building" }), _jsx("span", { children: item.adresse.mrc.nom })] })), _jsx(CompanyTitle, { children: item.entreprise.nom_legal }), _jsxs(Category, { children: [(_b = (_a = item.entreprise) === null || _a === void 0 ? void 0 : _a.code_scian_primaire) === null || _b === void 0 ? void 0 : _b.map(function (scian) {
                                            return _jsx("li", { children: scian.titre }, scian.id);
                                        }), (_d = (_c = item.entreprise) === null || _c === void 0 ? void 0 : _c.code_scian_secondaire) === null || _d === void 0 ? void 0 : _d.map(function (secscian) {
                                            return _jsx("li", { children: secscian.titre }, secscian.id);
                                        })] }), _jsxs(Text, { children: [item.adresse_to_str && (_jsxs("div", { children: [_jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/map-marker-blue-fonce.svg"), alt: "location" }), _jsx("span", { children: item.adresse_to_str })] })), item.montant && (_jsxs("div", { children: [_jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/cash.svg"), alt: "cash" }), _jsx("span", { children: item.montant ? moneyFormat(item.montant, langCode) : "-" })] })), _jsxs("div", { children: [item.is_avis && item.categorie_projet && (_jsxs(_Fragment, { children: [langCode === "fr" && item.categorie_projet.nom_fr && (_jsx(Tag, { children: item.categorie_projet.nom_fr })), langCode === "en" && item.categorie_projet.nom_en && (_jsx(Tag, { children: item.categorie_projet.nom_en }))] })), !item.is_avis && item.project_type === "jesuis_jecherche" && (_jsx(Tag, { children: projectTypeJSJC })), !item.is_avis && item.project_type !== "jesuis_jecherche" && (_jsx(Tag, { children: projectTypeName }))] }), _jsx(DateContainer, { children: application == "markets"
                                                ? item.is_avis == false && item.date_fermeture_avis
                                                    ? "".concat(f.format(new Date(item.date_publication_avis)), " - ").concat(f.format(new Date(item.date_fermeture_avis)))
                                                    : f.format(new Date(item.date_start_avis_timeline))
                                                : item.is_avis == true && item.date_fermeture_avis
                                                    ? "".concat(f.format(new Date(item.date_publication_avis)), " - ").concat(f.format(new Date(item.date_fermeture_avis)))
                                                    : f.format(new Date(item.date_start_avis_timeline)) }), item.description_fr &&
                                            item.description_fr != "None" &&
                                            item.description_en &&
                                            item.description_en != "None" && (_jsx("span", __assign({ className: "d-block mb-2" }, { children: langCode == "fr"
                                                ? item.description_fr.substring(0, 100)
                                                : item.description_en.substring(0, 100) })))] }), item.documents_count > 0 && (_jsxs("div", __assign({ className: "documents-count" }, { children: [_jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/doc-lines.svg"), alt: "document" }), " ", "".concat(item.documents_count, " documents")] })))] }) })))] }), i));
        }) })));
};
