var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React, { useRef, useContext, useEffect } from "react";
import ProjectsContext from "../../../../application/Context/ProjectsContext";
import MarketsContext from "../../../../application/Context/MarketsContext";
import { RenderProjects } from "./RenderProjects";
import { Range } from "react-range";
import { useTranslation } from "react-i18next";
import { YearView, MonthList, ProjectsContainer, SlideContainer, } from "./TimeLine-styles";
export var TimeLine = function (_a) {
    var _b;
    var application = _a.application;
    var context = application == "markets"
        ? useContext(MarketsContext)
        : useContext(ProjectsContext);
    var langCode = ((_b = document.getElementById("language-code")) === null || _b === void 0 ? void 0 : _b.value) || "en";
    var MONTHS_DATA_FR = context.MONTHS_DATA_FR, MONTHS_DATA_EN = context.MONTHS_DATA_EN, handleRangeChange = context.handleRangeChange, rangeValue = context.rangeValue;
    var MONTHS_DATA = langCode == "fr" ? MONTHS_DATA_FR : MONTHS_DATA_EN;
    var t = useTranslation(["projects"]).t;
    var ProjectContainerRef = useRef(null);
    var handleMouseDown = function (e) {
        var startX = e.clientX;
        var container = ProjectContainerRef.current;
        var handleMouseMove = function (e) {
            var distance = startX - e.clientX;
            container.scrollLeft += distance;
        };
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", function () {
            document.removeEventListener("mousemove", handleMouseMove);
        });
    };
    useEffect(function () {
        handleRangeChange([new Date().getFullYear()]);
    }, []);
    return (_jsxs(React.Fragment, { children: [_jsx("p", __assign({ style: { marginTop: "22px" } }, { children: _jsxs("strong", { children: [" ", application == "markets" ? t("Calendrier des marchés") : t("Calendrier des avis"), "  "] }) })), _jsxs(SlideContainer, { children: [_jsx("span", __assign({ style: { opacity: rangeValue[0] !== 2022 ? 1 : 0 } }, { children: "2022" })), _jsx(Range, { values: rangeValue, min: 2022, max: 2040, step: 1, onChange: handleRangeChange, renderTrack: function (_a) {
                            var props = _a.props, children = _a.children;
                            return (_jsxs("div", __assign({}, props, { style: __assign(__assign({}, props.style), { height: "6px", width: "100%", backgroundColor: "#C6CAD1", position: "relative" }) }, { children: [children, _jsx("div", __assign({ style: {
                                            position: "absolute",
                                            top: "10px",
                                            fontSize: "12px",
                                            left: "".concat(((rangeValue[0] - 2022) / (2040 - 2022)) * 100, "%"),
                                            transform: "translateX(-50%)",
                                            borderRadius: "3px",
                                        } }, { children: rangeValue[0] }))] })));
                        }, renderThumb: function (_a) {
                            var props = _a.props;
                            return (_jsx("div", __assign({}, props, { style: __assign(__assign({}, props.style), { height: "16px", width: "16px", borderRadius: "50%", backgroundColor: "#fff", border: "1px solid #707070" }) })));
                        } }), _jsx("span", __assign({ style: { opacity: rangeValue[0] !== 2040 ? 1 : 0 } }, { children: "2040" }))] }), _jsxs(ProjectsContainer, __assign({ ref: ProjectContainerRef, onMouseDown: handleMouseDown }, { children: [_jsx(MonthList, { children: MONTHS_DATA.map(function (month) {
                            return _jsx("span", { children: month.name }, month.id);
                        }) }), _jsx(YearView, { children: _jsx(RenderProjects, { application: application, rangeValue: rangeValue }) })] }))] }));
};
